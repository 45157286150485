.pageTitle {
  border: 2px solid #f2f2f2;
  text-align: center;
}

.pageTitle h2 {
  margin: 0;
  padding: 1rem 0;
  font-weight: 400;
  font-size: 1.6rem;
}

.pageTitle-language {
  padding: 0 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageTitle-language .language-switcher {
  position: absolute;
  right: 0;
  padding-right: 10px;
  z-index: 999;
}