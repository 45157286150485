.final80 {
  width: 80% !important;
  cursor: pointer !important;
}

@media only screen and (max-width: 600px) {
  .questionsBanner {
    font-size: 1rem !important;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #af1602 !important;
}
.transportDialogContent {
  position: absolute;
  width: 100%;
  z-index: 999;
  margin-top: 130px;
}
.contestLink {
  text-decoration: none;
  color: #af1602;
}
.contestTerms {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media only screen and (max-width: 550px) {
  .termsAcceptInner {
    display: flex;
    justify-content: space-between;
  }
  .contestLink {
    font-size: 14px;
  }
}

.recaptcha-wrapper{
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.recaptcha-error{
  border: 1px solid rgb(255, 0, 0);
  border-radius: 4px;
  padding: 0.5rem;
}

.welcomeBanner {
  background-color: #af1602;
  height: 95px;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  padding: 15px 23px;
}

.notificationsParagraph {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 20px !important;
}

.notificationTogglers {
  min-height: 80px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 26px 18px;
}

.notificationTogglersError{
  border-color: #f44336;
}

.notificationTogglers span {
  float: right;
  margin-top: 0px;
}

.registerBottomNav {
  position: fixed;
  width: 100%;
  height: 50px !important;
  bottom: 0;
}

.registerBottomNav span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.registerBottomNav span {
  opacity: 1 !important;
}

.MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly {
  padding-top: 8px !important;
}

.registerBottomNav button {
  width: 100%;
  max-width: 100%;
}

.registerError {
  position: absolute;
  width: 100%;
  margin-top: 0px !important;
}

.registerBackdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}

#redirectLink {
  display: none;
}